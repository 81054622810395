import React, { useState, useEffect } from 'react';

import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
    Table, TableBody, TableHead, TableRow, TableCell,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function Page() {

    const {id, date, token} = useParams()

    const [total, setTotal] = useState(null)
    const [range, setRange] = useState(null)
    const [coach, setCoach] = useState(null)
    const [items, setItems] = useState([])

    const load = async () => {
        const report = await axios.get(`https://api.arenasport.training/api/crm/business/coach/report/${id}/${date}/${token}`);

        setCoach(report?.data?.coach)
        setRange(report?.data?.range)
        setTotal(report?.data?.total)
        setItems(report?.data?.salary || [])
    }

    useEffect(() => {
        load()
    }, [id, date, token])

    return (
        <>
            <Card style={{padding: '0px 10px'}}>
                <Typography variant="h6">
                    {coach?.name}
                </Typography>
                <Typography>
                    Звіт з заробітної плати за період з {range?.from} по {range?.to}
                </Typography>
                <br />
                <Typography variant="h6">
                    Загальна сума: {total}грн
                </Typography>
            </Card>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>Сума</TableCell>
                        <TableCell>Послуга</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.date}</TableCell>
                            <TableCell>{item.salary}грн</TableCell>
                            <TableCell>{item.service?.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </>
    )
}


export default Page;
