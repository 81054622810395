import * as React from 'react';
import {
    Box
} from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage'
import CoachPage from './pages/CoachPage'
import CoachTaskPage from './pages/CoachTaskPage'
import ServicePage from './pages/ServicePage'
import ClientPage from './pages/ClientPage'
import MessagePage from './pages/MessagePage'
import StudioPage from './pages/StudioPage'
import AdminPage from './pages/AdminPage'
import AddressPage from './pages/AddressPage'
import PollPage from './pages/PollPage'
import CalendarPage from './pages/CalendarPage'
import SubscriptionPage from './pages/SubscriptionPage'
import ReportCoachPage from './pages/Report/Coach'
import MainMenu from './Menu'
import Toolbar from './Toolbar'

function App() {


    return (
        <Router>
            <Box sx={{ flexGrow: 1 }}>
                <Toolbar />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/coach" element={<CoachPage />} />
                    <Route path="/task" element={<CoachTaskPage />} />
                    <Route path="/service" element={<ServicePage />} />
                    <Route path="/client" element={<ClientPage />} />
                    <Route path="/message" element={<MessagePage />} />
                    <Route path="/studio" element={<StudioPage />} />
                    <Route path="/admin" element={<AdminPage />} />
                    <Route path="/address" element={<AddressPage />} />
                    <Route path="/poll" element={<PollPage />} />
                    <Route path="/subscription" element={<SubscriptionPage />} />
                    <Route path="/calendar" element={<CalendarPage />} />

                    <Route path="/report/coach/:id/:date/:token" element={<ReportCoachPage />} />
                </Routes>
            </Box>
        </Router>
    );
}

export default App;
