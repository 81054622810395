import * as React from 'react';
import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,

} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function HomePage() {

    return (
      <>
        <Card style={{padding: '0px 10px'}}>
                  <Typography variant="h5">
                      Bot для Тренерів
                  </Typography>

                      <Typography>
                          Примітка: якщо у вас з екрану зникла клавіатура з кнопками, будь-ласка, надішліть повідомлення "/start" (саме так, малими літерами) і вона знову зʼявиться.
                      </Typography>
                      <img style={{width: '100%'}} src="/images/keyboard.png" />
                      <br />
                      <Stack direction="row" useFlexGap>
                          <Typography>
                              Для того, щоб прочитати інструкції по кожній кнопці клавіатури, будь-ласка, оберіть відповідний пункт меню

                          &nbsp;<MenuIcon color="info" />&nbsp;

                          (лівий верхній кут даної сторінки)
                          </Typography>
                      </Stack>

                      <br />
                      <Typography variant="h5">
                          Відповіді на запитання
                      </Typography>
  </Card>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              Як додати заняття у розкладі?
                          </AccordionSummary>
                          <AccordionDetails>
                              <ol>
                                  <li>Натисніть на кнопку "🗓️&nbsp;Календар"</li>
                                  <li>Натисніть, на кнопку "👫 +Групове" або "🧍 +Індивідуальне"</li>
                                  <li>Оберіть тип "🔁 Дні тижня" (регулярне) або "1️⃣ Дата" (разове)</li>
                                  <li>Готово!</li>
                                  <li>Тепер можна налаштувати його параметри (див. розділ інструкції <Link to="/calendar">🗓️ Календар</Link>)</li>
                              </ol>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              Як переглянути заплановані заняття на тиждень?
                          </AccordionSummary>
                          <AccordionDetails>
                              <ol>
                                  <li>Натисніть на кнопку "🗓️&nbsp;Календар"</li>
                                  <li>Далі детальніше у розділі <Link to="/calendar">🗓️ Календар</Link></li>
                              </ol>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              Як скасувати заняття?
                          </AccordionSummary>
                          <AccordionDetails>
                              <ol>
                                  <li>Натисніть на кнопку "🗓️&nbsp;Календар"</li>
                                  <li>Оберіть потрібне заняття</li>
                                  <li>Натисніть на кнопку "🚫 Скасувати" (тимчасово приховати) або "❌ Видалити" (видалити назавжди)</li>
                              </ol>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              Скільки людей записано на заняття?
                          </AccordionSummary>
                          <AccordionDetails>
                              <ol>
                                  <li>Натисніть на кнопку "🗓️&nbsp;Календар"</li>
                                  <li>Оберіть потрібне заняття</li>
                                  <li>В повідомленні ви побачите "Кількість клієнтів" та  "Кількість відсутніх"</li>
                                  <li>Для детального перегляду, натисніть на кнопку "🤸🏽‍♂️ Відвідування"</li>
                              </ol>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              Як переглянути контактну інформацію клієнтів?
                          </AccordionSummary>
                          <AccordionDetails>
                              <ol>
                                  <li>Натисніть на кнопку "👫🏻 Клієнти"</li>
                                  <li>Оберіть потрібного клієнта</li>
                                  <li>Далі детальніше у розділі <Link to="/client">👫🏻 Клієнти</Link></li>
                              </ol>
                          </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              Як отримати фінансовий звіт?
                          </AccordionSummary>
                          <AccordionDetails>
                              <ol>
                                  <li>Натисніть на кнопку "💰 Фінанси"</li>
                                  <li>Оберіть вид звіту</li>
                              </ol>
                          </AccordionDetails>
                      </Accordion>
            </>

    );
}

export default HomePage;
